import { Button, Grid, Typography, Paper, Divider, Badge } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

const SingleUserComponent = () => {
    const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
    const { USER_ID, MANAGED_ID } = useParams();
    const history = useHistory();
    const [user, setUser] = useState();
    const [specialUser, setSpecialUser] = useState();

    const getUserDetails = () => {
        axios.get(`${api_link}/users/user/${MANAGED_ID}`).then((response) => {
            setUser(response.data.data);
        }).catch((error) => {
            console.log(error.response);
        });
    };

    const getSpecialUserDetails = () => {
        axios.get(`${api_link}/users/special-user/${MANAGED_ID}`).then((response) => {
            setSpecialUser(response.data.user);
        }).catch((error) => {
            console.log(error.response);
        });
    };

    useEffect(() => {
        getUserDetails();
        getSpecialUserDetails();
    }, []);

    return (
        <Grid container spacing={4} style={{ padding: '20px' }}>
            <Grid container xs={12} spacing={4} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0px' }}>
                <Typography variant="h5" style={{ marginLeft: 10, color: 'white' }}>{user?.name}</Typography>
                <Button onClick={() => history.push(`/super-admin/${USER_ID}/users-management`)} variant="contained" color="primary">All users</Button>
            </Grid>

            <Divider style={{ background: "#fff", height: "2px" }} />

            {user && (
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={7}>
                        <Paper style={{ padding: '20px', backgroundColor: '#f5f5f5', gap: 4 }}>
                            <Typography variant="h5">Organization Details</Typography>
                            <Typography>Email: <b>{user.email}</b></Typography>
                            <Typography>Phone: <b>{user.phone}</b></Typography>
                            <Typography>Role: <b>{user.role}</b></Typography>
                            <Typography>Date registered: <b>{user.date_registered}</b></Typography>
                            <Typography>Last Login: <b>{user.last_login}</b></Typography>
                            <Typography>Account status: <b>{user.status}</b></Typography>
                        </Paper>
                    </Grid>
                </Grid>
            )}
            <Grid container xs={12} sm={8} style={{ marginTop: 20 }} >
                <Paper style={{ padding: 10, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="subtitle2">Special user details</Typography>
                </Paper>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12} sm={7}>
                    <Paper style={{ padding: '20px', backgroundColor: '#f5f5f5', gap: 4 }}>
                        <Typography>Name: <b>{specialUser?.firstName} {specialUser?.middleName} {specialUser?.lastName}</b></Typography>
                        <Typography>Email: <b>{specialUser?.email}</b></Typography>
                        <Typography>Phone: <b>{specialUser?.phoneNumber}</b></Typography>
                        <Typography>Grade Levels: {specialUser?.gradeLevels?.map((level, index) => <b key={index}>{level} </b>)}</Typography>
                        <Typography>Date registered: <b>{specialUser?.date_registered}</b></Typography>
                        <Typography>Last Login: <b>{specialUser?.last_login}</b></Typography>
                        <Typography>Account status: <b>{specialUser?.status}</b></Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SingleUserComponent;
