// MAKE THIS FILE MORE REUSABLE
import React, { useState, useRef, useEffect, useContext } from "react";
import { NotificationContext } from "../../components/Notifications/NotificationContext";
import { makeStyles, IconButton, Button } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import { RiDeleteBin3Line, RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineFileImage, AiOutlineFileWord, AiOutlineFileExcel, AiFillFile, AiOutlineFileText } from "react-icons/ai";
import { FaRegFilePowerpoint } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { useParams } from "react-router-dom";
import CircleSpinner from "../../components/CircleSpinner/CircleSpinner"
import axios from "axios";

const useStyles = makeStyles({
  root: {
    background: "#e9e9e9",
    padding: "5px",
    marginTop: "10px",
  },
  stagedFile: {
    display: "flex",
    justifyContent: "space-between",
    borderLeft: "3px solid #ff5e00",
    margin: "10px",
    padding: "10px",
    background: "#FFFFFF",
    fontSize: "12px",
    letterSpacing: "1px",
  },
  stagedFileTypeIcon: {
    fontSize: "25px",
    marginRight: "10px",
    color: "grey",
  },
  stagedFileDeleteRoot: {
    width: "20px",
    height: "20px",
  },
  stagedFileDelete: { color: "#ff5e00", fontSize: "20px" },
  dbFileDelete: { color: "#ff5e00", fontSize: "15px" },
  warning: {
    background: "#fffbdd",
    padding: "10px",
    textAlign: "center",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #d3cfb7",
  },
  link: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#ff5e00",
    },
  },
});

function SingleFile({ file, index, handleRemoveFileFromStage }) {
  const classes = useStyles();
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED;

  const { createNotification } = useContext(NotificationContext);

  const {
    SUBJECT_ID,
    LESSON_ID,
    ASSIGNMENT_ID,
    ASSESSMENT_ID,
    ORGANIZATION_ID,
    TEACHER_ID,
    STUDENT_ID,
  } = useParams();

  // states
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // We have to check if the component is still mounted
  // before setting states in async fns
  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  const [teacher, setTeacher] = useState();
  const getTeacher = () => {
    if (TEACHER_ID) {
      var options = {
        method: 'GET',
        url: `${api_link}/instructors/single-instructor/${TEACHER_ID}`
      };

      axios.request(options).then(function (response) {
        setTeacher(response.data.data);
      }).catch(function (error) {
        console.error(error);
      });
    }
  }

  const [student, setStudent] = useState()
  const getStudent = () => {
    if (STUDENT_ID) {
      var options = {
        method: 'GET',
        url: `${api_link}/students/single-student/${STUDENT_ID}`
      };

      axios.request(options).then(function (response) {
        setStudent(response.data.data);
      }).catch(function (error) {
        console.error(error);
      });
    }
  }

  useEffect(() => {
    getTeacher();
    getStudent();
  }, [TEACHER_ID, STUDENT_ID]);

  if (!student && !teacher) return <CircleSpinner />;

  const subject = activeRoute("/teacher")
    ? teacher?.subjects.find((subject) => subject.subjectId === SUBJECT_ID) || {}
    : activeRoute("/student")
      ? student?.subjects.find((subject) => subject.subjectId === SUBJECT_ID) || {}
      : null;

  const lesson = subject?.lessons?.find((lesson) => lesson?._id === LESSON_ID);

  const assignment = subject?.assignments?.find((assignment) => assignment?._id === ASSIGNMENT_ID);

  const assessment = subject?.assessments?.find((assessment) => assessment._id === ASSESSMENT_ID);

  // verifies if routeName is the one active (in browser input)
  // we use this fn to check if route contains /lesson , /assignment , /assessment
  // This allows us to conditionally apply delete logic depending on what we are
  // deleting(oneOF: lessonFiles, assessmentFiles, assignmentFiles)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const switchFileTypeIcon = (type) => {
    switch (type) {
      case "application/pdf":
        return <VscFilePdf className={classes.stagedFileTypeIcon} />;
      case "text/plain":
        return <AiOutlineFileText className={classes.stagedFileTypeIcon} />;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <AiOutlineFileWord className={classes.stagedFileTypeIcon} />;
      case "application/vnd.ms-excel":
        return <AiOutlineFileExcel className={classes.stagedFileTypeIcon} />;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return <FaRegFilePowerpoint className={classes.stagedFileTypeIcon} />;
      case "image/png":
        return <AiOutlineFileImage className={classes.stagedFileTypeIcon} />;
      case "image/jpeg":
        return <AiOutlineFileImage className={classes.stagedFileTypeIcon} />;
      default:
        return <AiFillFile className={classes.stagedFileTypeIcon} />;
    }
  };

  const handleRemoveFileFromCloudAndNeo = () => {
    let response;
    let active = "";
    if (activeRoute('/assessment')) {
      response = axios.post(`${api_link}assessments/assessments/${ASSESSMENT_ID}/files/${file._id}`);
      active = "assessments";
    } else if (activeRoute('/assignment')) {
      response = axios.post(`${api_link}assignments/assignments/${ASSIGNMENT_ID}/files/${file._id}`);
      active = "assignments";
    } else if (activeRoute('/lesson')) {
      response = axios.post(`${api_link}lessons/lessons/${LESSON_ID}/files/${file._id}`);
      active = "lessons";
    } else {
      createNotification({
        message: `nothing to do delete please`,
        status: "warning",
      })
      return
    }

    response.then(() => {
      createNotification({
        message: `File ${file.name} removed from the cloud.`,
        status: "success",
      });
      window.location.reload();
    }).catch((error) => {
      createNotification({
        message: `Error: ${error.message}`,
        status: "error",
      });
    });
  };

  // ############################################################################
  // Dialog handlers
  // ############################################################################
  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  // TODO: Refactor this logic, too confusing (keep in mind that it affects how staged files are removed from the stage)
  return activeRoute("/teacher") ||
    activeRoute("/admin") ||
    // This last conditional allows the remove from stage icon to appear on applicants uploaded files before they are sent to server
    activeRoute("/careers") ? (
    <div className={classes.stagedFile}>
      <a className={classes.link} href={file.url} target="_blank" rel="noreferrer">
        <div style={{ display: "flex" }}>
          {switchFileTypeIcon(file.type)}
          <p style={{ lineHeight: "25px" }}>{file.name}</p>{" "}
        </div>
      </a>
      {handleRemoveFileFromStage ? (
        <IconButton onClick={() => { handleRemoveFileFromStage(index); }} className={classes.stagedFileDeleteRoot}>
          <RemoveIcon className={classes.stagedFileDelete} />
        </IconButton>
      ) : (
        <div>
          <Button onClick={handleConfirmDialogOpen} style={{ width: "40px", minWidth: "40px" }}>
            <RiDeleteBin6Line className={classes.dbFileDelete} />
          </Button>
          <ConfirmDialog
            open={confirmDialogOpen}
            handleClose={handleConfirmDialogClose}
            actionHandler={handleRemoveFileFromCloudAndNeo}
            actionBtnMessage="Delete this File"
            actionBtnMessageLoading={"Deleting file..."}
            warningActionIcon={
              <RiDeleteBin3Line
                fontSize="25"
                style={{ color: "rgb(255, 94, 0)" }}
              />
            }
            requireConfirmInputText={false}
            confirmMessageTitle="Are you sure you want to delete this file?"
            confirmMessage={
              <>
                <>
                  This action <strong>cannot</strong> be undone. The file{" "}
                  <strong>"{file.name}"</strong> will be permanently deleted.
                </>
              </>
            }
          />{" "}
        </div>
      )}
    </div>
  ) : activeRoute("/student") ? (
    <div className={classes.stagedFile}>
      <a className={classes.link} href={file.url} target="_blank" rel="noreferrer">
        <div style={{ display: "flex" }}>
          {switchFileTypeIcon(file.type)}
          <p style={{ lineHeight: "25px" }}>{file.name}</p>{" "}
        </div>
      </a>
      {handleRemoveFileFromStage ? (
        <IconButton
          onClick={() => {
            handleRemoveFileFromStage(index);
          }}
          className={classes.stagedFileDeleteRoot}
        >
          <RemoveIcon className={classes.stagedFileDelete} />
        </IconButton>
      ) : null}
    </div>
  ) : (
    <div className={classes.stagedFile}>
      <a className={classes.link} href={file.url} target="_blank" rel="noreferrer">
        <div style={{ display: "flex" }}>
          {switchFileTypeIcon(file.type)}
          <p style={{ lineHeight: "25px" }}>{file.name}</p>{" "}
        </div>
      </a>
    </div>
  );
}

export default SingleFile;
