import { TextField, Button, Container, Box, Snackbar } from '@material-ui/core';
import logo from '../../assets/vlearned-final-logo.webp'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie'

import { useState } from 'react';
import axios from 'axios';
import { Alert } from '@material-ui/lab';

const Verifyactivation = () => {
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED

  const handleClick = () => {
    setOpen(true);
  };
  const handleError = () => {
    setError(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setError(false);
  };

  const handleEmailForToken = (e) => {
    var options = {
      method: 'POST',
      url: `${api_link}/users/get-token`,
      data: { email: email }
    };

    axios.request(options).then(function (response) {
      const user_id = response.data.data.user_id;
      // localStorage.setItem('activation_user_id', user_id);
      Cookies.set('activation_user_id', user_id, { domain: 'vlearned.com' });
      handleClick();
    }).catch(function (error) {
      handleError();
      if (error.response.data.error === "User not found") {
        const errorMessage = document.getElementById('errorMessage');

        if(errorMessage) {
          errorMessage.innerText = "User not found";
        }
      }
    });
  }

  return (
    <Container maxWidth="sm" className='container-flex'>
      <Box height={500} width={'100%'} my={4} display="flex" alignItems="center" gap={4} p={2} alignSelf="center">
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Login successful!
          </Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" id='errorMessage' color='red'>
            This is an error message!
          </Alert>
        </Snackbar>

        <form className='account-bg-white'>
          <Link to='/'><center><img src={logo} alt="logo" width={'60%'} /></center></Link>

          <p className='subhead'>Enter email to get token.</p>

          <TextField type='email' id="outlined-basic" label="Email" required variant="outlined" className='w-100' onChange={(e) => setEmail(e.target.value)} />

          <Button variant="contained" onClick={handleEmailForToken} color="primary" className='mt-7'>Get token</Button>

          <p className='option'><Link to='/login' className='link text-danger'>Go back</Link></p>
        </form>
      </Box>
    </Container>
  )
}

export default Verifyactivation