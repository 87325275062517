import { useEffect } from 'react';

/* global browser, chrome */

const AuthScriptComponent = () => {
  useEffect(() => {
    // Detect if the browser is using the WebExtensions API (browser) or Chrome API
    const _browser = typeof browser !== 'undefined' ? browser : (typeof chrome !== 'undefined' ? chrome : null);

    // Check if _browser is valid before proceeding
    if (!_browser || !_browser.runtime || !_browser.runtime.sendMessage) {
      console.warn('Browser runtime API is not supported in this browser.');
      return;
    }

    const messageHandler = (event) => {
      // Ensure the event is trusted and the data format is as expected
      if (event.isTrusted && event.data && event.data.type === 'auth') {
        const { account } = event.data;
        
        // Validate account and credentials structure
        if (account && account.id && account.credentials && account.credentials.ipsec_password) {
          // Send a message using the browser's runtime API
          _browser.runtime.sendMessage(event.data);
        } else {
          console.warn('AuthScriptComponent: Invalid account data received.');
        }
      } else {
        console.warn('AuthScriptComponent: Received message is not trusted or is not an auth message.');
      }
    };

    // Attach the event listener
    window.addEventListener('message', messageHandler);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  return null;
};

export default AuthScriptComponent;
