import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, Button, TextField, CircularProgress, Badge } from "@material-ui/core";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { RiDeleteBin3Line } from "react-icons/ri";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { useTranslation, Trans } from "react-i18next";
import { NotificationContext } from "../../components/Notifications/NotificationContext";

const StudentManagement = () => {
    const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
    const [students, setStudent] = useState([]);
    const [originalStudents, setStudents] = useState([]);
    const { createNotification } = React.useContext(NotificationContext);

    const { USER_ID } = useParams();
    const history = useHistory();

    const getOrganizations = async () => {
        await axios.get(`${api_link}/students`)
            .then((response) => {
                setStudent(response.data.data);
                setStudents(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSearch = (event) => {
        const keyword = event.target.value.toLowerCase();
        if (keyword === "") {
            setStudent(originalStudents);
        } else {
            const filtered = originalStudents.filter((user) =>
                user.name.toLowerCase().includes(keyword)
            );
            setStudent(filtered);
        }
    };

    const [deleteOrganization, setDeleteOrganization] = useState(false);
    const handleCloseDailog = () => {
        setDeleteOrganization(false)
    }

    const [name, setName] = useState('');
    const [student_id, setStudent_id] = useState('');
    const handleDeleteion = (name, student_id) => {
        setDeleteOrganization(true)
        setName(name);
        setStudent_id(student_id);
    }

    const handleDeleteStudent = async () => {
        await axios.post(`${api_link}/students/delete-student`, { userId: USER_ID, student_id }).then((response) => {
            createNotification({
                message: `${name} deleted successfully!`,
                status: "error",
            });
            setDeleteOrganization(false);
            getOrganizations();
        }).catch((error) => {
            console.log(error)
            createNotification({
                message: `${name} not deleted.`,
                status: "erro",
            });
        });
    }

    useEffect(() => {
        setTimeout(() => <CircularProgress size={24} />, 2000)
        getOrganizations();
    }, []);

    return (
        <Grid container xs={12}>
            <Typography variant="h4" style={{ color: 'white' }}>Students Management<Badge badgeContent={students.length} color="primary"><span style={{ color: "transparent" }}>{" c "}</span></Badge></Typography>

            <Grid container spacing={3} style={{ padding: 2, marginTop: 40 }}>
                <Grid xs={12} style={{ backgroundColor: '#fff', borderRadius: 4, marginBottom: 20 }}>
                    <TextField type='search' id="outlined-basic" variant="outlined" className='w-100' onChange={handleSearch} style={{ width: '100%' }} placeholder="Search students"/>
                </Grid>
                {students.map((user, index) => {
                    return (
                        <Grid key={index} item xs={12} sm={6} md={3}>
                            <Card style={{ padding: '20px 10px 30px 10px', textAlign: 'center', display: 'flex', gap: 4, alignItems: 'center' }}>
                                <Grid>
                                    {/* <img src={organization?.logoUrl} style={{ borderRadius: '100%', width: 100, height: 100, backgroundColor: 'grey' }} alt={organization?.title[0]} /> */}
                                    <p style={{ borderRadius: '100%', border: '2px double orange', width: 60, height: 60, backgroundColor: 'grey', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 28 }}>{user?.name[0]}</p>
                                </Grid>
                                <Grid spacing={2} xs={12} sm={12} style={{ marginTop: 10, justifyContent: 'left' }}>
                                    <div style={{ marginBottom: 10, paddingLeft: 10 }}>
                                        <Typography variant="h5" style={{ width: '100%', marginTop: 20, textAlign: 'left', fontSize: 19 }}>{user?.name}</Typography>
                                        <Typography variant="subtitle2" style={{ width: '100%', marginTop: 4, color: '#fd8520', textAlign: 'left' }}>{user?.organizationId?.title}</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                        <Button onClick={() => history.push(`/super-admin/${USER_ID}/students-management/${user?._id}`)} variant="contained" color="primary" style={{ marginRight: 4, borderRadius: '50px' }}>View</Button>
                                        <Button variant="contained" color="info" style={{ marginRight: 4, borderRadius: '50px' }}>Suspend</Button>
                                        <Button variant="contained" color="secondary" style={{ marginRight: 4, borderRadius: '50px' }} onClick={() => handleDeleteion(user?.name, user?._id)}>Delete</Button>
                                    </div>
                                </Grid>
                            </Card>
                        </Grid>
                    );
                })}

                {students.length === 0 && <Grid style={{ backgroundColor: '#e96364', width: '100%', padding: 10, textAlign: 'center', color: 'white', marginTop: 20 }}><Typography variant="h6">No students found</Typography></Grid>}
            </Grid>

            <ConfirmDialog
                open={deleteOrganization}
                handleClose={handleCloseDailog}
                actionHandler={handleDeleteStudent}
                actionBtnMessage={"Confirm deletion"}
                // handleChangeConfirmInputText={name}
                confirmMessage={<Trans i18nKey="Instrucotr will be deleted"> This action <strong>cannot</strong> be undone. This will permanently delete this <b>{name}</b> instructor</Trans>}
                warningActionIcon={
                <RiDeleteBin3Line fontSize="25" style={{ color: "rgb(255, 94, 0)" }} />
                }
                requireConfirmInputText={false}
                confirmMessageTitle={"Are you sure you want to continue?"}
            />
        </Grid>
    );
}

export default StudentManagement;
