import React, { useState } from 'react';
import { TextField, Button, Container, Box, InputAdornment, IconButton, Snackbar, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import logo from '../../assets/vlearned-final-logo.webp';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import emailjs from "emailjs-com";
import Cookies from 'js-cookie'

const Register = () => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED;

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword1 = (event) => event.preventDefault();
  const handleMouseDownPassword2 = (event) => event.preventDefault();

  const handleClick = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!validateEmail(value)) {
      setEmailError('Error: Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const validatePassword = (password) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;

    if (password.length < 8) return 'Error: Password must be at least 8 characters long.';
    if (!hasNumber.test(password)) return 'Error: Password must contain at least one number.';
    if (!hasSpecialChar.test(password)) return 'Error: Password must contain at least one special character.';
    if (!hasUppercase.test(password)) return 'Error: Password must contain at least one uppercase letter.';
    if (!hasLowercase.test(password)) return 'Error: Password must contain at least one lowercase letter.';

    return '';
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword1(value);
    const error = validatePassword(value);
    setPasswordError(error);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password1 !== password2) {
      setErrorMessage('Error: passwords do not match');
      handleClick();
      setLoading(false);
      return;
    }

    if (passwordError || emailError) {
      setErrorMessage(passwordError || emailError);
      handleClick();
      setLoading(false);
      return;
    }

    if (name === '' || email === '' || phone === '' || password1 === '' || password2 === '') {
      setErrorMessage('Error: Please fill in all the required fields');
      handleClick();
      setLoading(false);
      return;
    }

    var options = {
      method: 'POST',
      url: `${api_link}/users/register`,
      data: {
        name: name,
        phone: phone,
        email: email,
        password: password1,
      },
    };

    await axios.request(options).then(async (response) => {
      if (response.data.status === 200) {
        setErrorMessage('Success! You can now activate your account using code sent on your email.');
        Cookies.set('email', response.data.data.email, { expires: 7, domain: 'vlearned.com' });
        Cookies.set('user_token', response.data.data.user_token, { expires: 7, domain: 'vlearned.com' });

        const templateParams = {
          to_email: email,
          subject: 'Token for activate account.',
          message: `Hello ${name},\n\n use the token ${response.data.data.token} to activate your account`,
        };

        await emailjs.send('service_s0bdict', 'template_ms6gzk6', templateParams, 'vlPXVVEoQgATcwO-h');

        setOpen(true);
        setLoading(false);
        setTimeout(() => window.location.href = '/activate-account', 2000);
      } else {
        setErrorMessage(`Error: ${response.data.message}`);
        setOpen(true);
        setLoading(false);
        console.log(response)
      }
    }).catch(function (error) {
      // console.log(error)
      setLoading(false);
      if (error.response.data.message === "use different email or phone") {
        setErrorMessage('Error: User with this email already exists.');
      } else {
        setErrorMessage('Error: Registering user');
      }
      setOpen(true);
    });
  };

  return (
    <Container maxWidth="sm" style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '100vh' }}>
      <Box height={500} width={'100%'} gap={4} p={2} marginTop={'-140px'}>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={errorMessage.startsWith('Error:') ? 'error' : 'success'} id='errorMessage'>
            {errorMessage}
          </Alert>
        </Snackbar>

        <form className='account-bg-white'>
          <center><Link to='/'><img src={logo} alt="logo" width={'60%'} /></Link></center>
          <TextField type='text' label="Name" variant="outlined" className='w-100' onChange={(e) => setName(e.target.value)} required />
          <TextField type='tel' label="Phone" variant="outlined" className='w-100' onChange={(e) => setPhone(e.target.value)} required />
          <TextField type='email' label="Email" variant="outlined" className='w-100' onChange={handleEmailChange} error={!!emailError} helperText={emailError} required />

          <TextField type={showPassword1 ? 'text' : 'password'} label="Password" variant="outlined" className='w-100' required value={password1} onChange={handlePasswordChange} error={!!passwordError} helperText={passwordError} InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword1} onMouseDown={handleMouseDownPassword1}>
                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          />

          <TextField type={showPassword2 ? 'text' : 'password'} label="Confirm Password" variant="outlined" required className='w-100' value={password2} onChange={(e) => setPassword2(e.target.value)} InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword2} onMouseDown={handleMouseDownPassword2}>
                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          />

          {loading ? (
            <Button variant="contained" color="primary" className='mt-7' disabled>
              <CircularProgress size={24} />
            </Button>
          ) : (
            <Button variant="contained" color="primary" className='mt-7' onClick={handleRegistration}>
              Register
            </Button>
          )}

          <p className='option'>I have an account? <Link to='/login' className='link text-danger'>Login</Link></p>
        </form>
      </Box>
    </Container>
  );
};

export default Register;
