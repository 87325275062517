import { Button, Grid, Typography, Paper, Divider, Badge } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    title: {
        fontSize: "14px",
        fontWeight: "600",
        marginBottom: "20px",
        marginTop: "20px",
    },
    cell: { color: "grey" },
    cellTitle: { fontWeight: "bold" },
});

const SingleOrganization = () => {
    const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
    const { USER_ID, ORGANIZATION_ID } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [organization, setOrganization] = useState();

    const getOrganizationDetails = () => {
        axios.get(`${api_link}/organizations/single_organization/${ORGANIZATION_ID}`).then((response) => {
            setOrganization(response.data.data);
        }).catch((error) => {
            console.log(error.response);
        });
    };

    useEffect(() => {
        getOrganizationDetails();
    }, []);

    return (
        <Grid container spacing={4} style={{ padding: '20px' }}>
            <Grid container xs={12} spacing={4} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0px' }}>
                <Typography variant="h5" style={{ marginLeft: 10, color: 'white' }}>{organization?.title}</Typography>
                <Button onClick={() => history.push(`/super-admin/${USER_ID}/organization-management`)} variant="contained" color="primary">All Organizations</Button>
            </Grid>

            <Divider style={{ background: "#fff", height: "2px" }} />

            {organization && (
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={7}>
                        <Paper style={{ padding: '20px', backgroundColor: '#f5f5f5', gap: 4 }}>
                            <Typography variant="h5">Organization Details</Typography>
                            <Typography>Email: <b>{organization?.email}</b></Typography>
                            <Typography>Phone: <b>{organization?.phoneNumber}</b></Typography>
                            <Typography>Address: <b>{organization?.address1}</b></Typography>
                            <Typography>Postal Code: <b>{organization?.postal_code}</b></Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Paper style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
                            <Typography variant="h5">Users</Typography>
                            <Grid container spacing={2} style={{ marginTop: 8 }}>
                                <Grid item>
                                    <Typography variant="h6" style={{ marginBottom: 8 }}>Admins</Typography>
                                    <Button variant="contained" color="primary">{organization?.admins.length}</Button>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" style={{ marginBottom: 8 }}>Students</Typography>
                                    <Button variant="contained" color="primary">{organization?.students.length}</Button>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" style={{ marginBottom: 8 }}>Teachers</Typography>
                                    <Button variant="contained" color="primary">{organization?.teachers.length}</Button>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" style={{ marginBottom: 8 }}>Subjects</Typography>
                                    <Button variant="contained" color="primary">{organization?.subjects.length}</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            )}
            {/* <Grid container xs={12} sm={8} style={{ marginTop: 20 }} >
                <Paper style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
                    <Typography variant="h5">Subscription Details</Typography>
                </Paper>
            </Grid> */}
            <Grid container xs={12} sm={8} style={{ marginTop: 20 }} >
                <Paper style={{ padding: 10, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="subtitle2">Subscription Details</Typography>
                </Paper>
            </Grid>

            <Grid container xs={12} sm={8} style={{ marginTop: 20, marginBottom: 10 }} >
                <Paper style={{ padding: 10, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="subtitle2">User details</Typography>
                </Paper>
            </Grid>

            <Grid container spacing={2} xs={12} style={{ paddding: 10 }}>
                <Grid item xs={12} style={{ paddding: 10 }}>
                    {organization?.admins.length > 0 && (
                        <TableContainer component={Paper} style={{ background: "#f4f4f4" }} elevation={3}>
                            <Typography variant="h5" style={{ margin: '10px 0px 0px 16px' }}>Administrators</Typography>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.cellTitle}></TableCell>
                                        <TableCell className={classes.cellTitle}>Name</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Email</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Phone</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Role</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {organization?.admins.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {row.email}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {row.phone}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {row.role}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                <span style={{ backgroundColor: row.status === 'Active' ? 'green' : 'orange', padding: 10, borderRadius: 4, color: 'white' }}>{row.status}</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
                <Grid item xs={12} style={{ paddding: 10 }}>
                    {organization?.teachers.length > 0 && (
                        <TableContainer component={Paper} style={{ background: "#f4f4f4" }} elevation={3}>
                            <Typography variant="h6" style={{ margin: '10px 0px 0px 16px' }}>Teachers</Typography>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.cellTitle}></TableCell>
                                        <TableCell className={classes.cellTitle}>Name</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Email</TableCell>
                                        {/* <TableCell className={classes.cellTitle} align="left">Phone</TableCell> */}
                                        <TableCell className={classes.cellTitle} align="left">Designation title</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Subjects</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {organization?.teachers.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.firstName} {row?.middleName} {row?.lastName}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {row?.email}
                                            </TableCell>
                                            {/* <TableCell className={classes.cell} align="left">
                                                {row?.phone}
                                            </TableCell> */}
                                            <TableCell className={classes.cell} align="left">
                                                {row?.designationTitle}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {row?.subjects.map((subject) => <span>{subject}</span>)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
                <Grid item xs={12} style={{ paddding: 10 }}>
                    {organization?.students.length > 0 && (
                        <TableContainer component={Paper} style={{ background: "#f4f4f4" }} elevation={3}>
                            <Typography variant="h6" style={{ margin: '10px 0px 0px 16px' }}>Students</Typography>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.cellTitle}></TableCell>
                                        <TableCell className={classes.cellTitle}>Name</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Email</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Address</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">Grade Level</TableCell>
                                        <TableCell className={classes.cellTitle} align="left">completed Training</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {organization?.students.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {row.email}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {row.address}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {row.gradeLevel}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                <span style={{ backgroundColor: row.completedTraining ? 'green' : 'orange', padding: 10, borderRadius: 4, color: 'white' }}>{row.completedTraining ? 'Yes' : 'No'}</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SingleOrganization;
