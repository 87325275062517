import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import ProfilePopper from "../ProfilePopper/ProfilePopper";
import ButtonWithExternalLink from "../ButtonWithExternalLink/ButtonWithExternalLink";
import styles from "./navbarStyle";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsPopper from "../Notifications/notification-popper";

const useStyles = makeStyles(styles);

export default function Navbar(props) {
  const classes = useStyles();
  const { logoUrl, title, type } = props.organization || {};
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const handleNotificationsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setNotificationsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setNotificationsOpen(false);
    setAnchorEl(null);
  };

  return (
    <AppBar className={clsx(classes.appBar, { [classes.appBarShift]: props.open })}>
      <Toolbar className={classes.container}>
        <div style={{ display: "flex", flexGrow: 1, marginLeft: "10px" }}>
          {/* Logo and Title */}
          {!props.organization && (
            <img src="https://res.cloudinary.com/teenkevo-cloud/image/upload/v1658287281/vlearned-beta/vlearned-final-logo_pazz2u_oypnii.webp" alt="logo" className={classes.img} />
          )}
          {logoUrl && (
            <Hidden xsDown implementation="css">
              <img src={logoUrl || "https://storage.googleapis.com/welearned-bucket/logoPlaceholder.png"} alt="logo" style={{ justifyContent: "center", border: "1px grey solid", maxHeight: "70px", maxWidth: "70px", borderRadius: "70px" }} />
            </Hidden>
          )}

          <div style={{ padding: "10px", display: "table" }}>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <p style={{ marginBottom: "5px", fontWeight: "800", fontSize: "15px", color: "#ff903e", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2, overflow: "hidden", textOverflow: "ellipsis" }}>
                {title}
              </p>
              <p style={{ fontSize: "12px" }}>{type}</p>
            </div>
          </div>
        </div>

        <Hidden xsDown implementation="css">
          <ButtonWithExternalLink link="https://support.vlearned.com" message="Contact Support" />
        </Hidden>

        <IconButton onClick={handleNotificationsClick} style={{ cursor: 'pointer' }}>
          {/* <NotificationsIcon style={{ color: "#FFFFFF" }} /> */}
          <NotificationsIcon style={{ color: "#FFFFFF" }} />
          {hasUnreadNotifications && (
            <span style={{
              position: 'absolute',
              top: 8,
              right: 8,
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: 'blue',
            }} />
          )}
        </IconButton>

        <ProfilePopper organization={props.organization} />
      </Toolbar>

      <NotificationsPopper anchorEl={anchorEl} open={notificationsOpen} onClose={handleClose} setHasUnreadNotifications={setHasUnreadNotifications} />
    </AppBar>
  );
}

Navbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
