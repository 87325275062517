import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import { Grid, Card, Typography } from "@material-ui/core";
import './styles.css';
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from 'js-cookie'

const DashboardManagement = () => {
    const { USER_ID } = useParams();
    const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
    const [organizations, setOrganizations] = useState([]);
    const [users, setUsers] = useState([]);
    // const user_logged_in = localStorage.getItem('user_details');
    const user_logged_in = Cookies.get('user_details');

    const date = new Date();
    const year = date.getFullYear();

    const getOrganizations = () => {
        axios.get(`${api_link}/organizations`).then((response) => {
            setOrganizations(response.data.data);
        }).catch((error) => {
            console.log(error);
        });
    };

    const getUsers = () => {
        if(user_logged_in) {
            let user = JSON.parse(user_logged_in);
            axios.get(`${api_link}/users`, {  headers: { 'token': user.user_token } }).then((response) => {
                setUsers(response.data.data);
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const superAdmins = users.filter(user => user.role === "Super Administrator").length;

    useEffect(() => {
        if(!JSON.parse(user_logged_in)) {
            window.location.href = "/";
        }
        getOrganizations();
        getUsers();
    }, []);
    const formatDate = (dateString) => {
        const isoDateRegex = /^ISODate\('(.+?)'\)$/;
        const match = dateString.match(isoDateRegex);

        if (match && match[1]) {
            return match[1].split('T')[0];
        } else if (!isNaN(dateString)) {
            const timestamp = parseInt(dateString, 10);
            if (!isNaN(timestamp)) {
                const date = new Date(timestamp);
                return date.toISOString().split('T')[0];
            }
        } else {
            const dateCheck = new Date(dateString);
            if (!isNaN(dateCheck.getTime())) {
                return dateString;
            }
        }
        return null;
    };

    const calculateMonthlyRegistrations = (data, dateField) => {
        const monthlyCounts = Array(12).fill(0);

        data.forEach(item => {
            const formattedDate = formatDate(item[dateField]);
            if (formattedDate) {
                const date = new Date(formattedDate);
                if (date.getFullYear() === year) {
                    monthlyCounts[date.getMonth()] += 1;
                } else {
                    console.log(`Invalid date for item:`, item);
                }
            } else {
                console.log(`Invalid date format for item:`, item);
            }
        });

        return monthlyCounts;
    };


    const organizationCounts = calculateMonthlyRegistrations(organizations, 'createdAt');
    const userCounts = calculateMonthlyRegistrations(users, 'date_registered');

    return (
        <Grid container spacing={3} sx={{ padding: 2 }}>
            <Grid item xs={12} sm={6} md={3}>
                <Card style={{ padding: '40px 0px', textAlign: 'center' }}>
                    <Typography variant="h6">$304.456K</Typography>
                    <Typography variant="body2" color="secondary">Total Revenue</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Card style={{ padding: '40px 0px', textAlign: 'center' }}>
                    <Typography variant="h6">{organizations?.length}</Typography>
                    <Typography variant="body2" color="secondary">Total organizations</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Card style={{ padding: '40px 0px', textAlign: 'center' }}>
                    <Typography variant="h6">{users?.length}</Typography>
                    <Typography variant="body2" color="secondary">Total Users</Typography>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Card style={{ padding: '40px 0px', textAlign: 'center' }}>
                    <Typography variant="h6">{superAdmins}</Typography>
                    <Typography variant="body2" color="secondary">Total Super admins</Typography>
                </Card>
            </Grid>

            <Grid container spacing={3} sx={{ padding: 2 }}>
                <Grid item xs={12} sm={6}>
                    <Card style={{ padding: '30px 20px' }}>
                        <Stack sx={{ width: '100%' }}>
                            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                                Total organizations registered per month <span style={{ color: '#f07e1d', fontSize: 16 }}>({year})</span>
                            </Typography>
                            <LineChart
                                xAxis={[{ data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] }]}
                                // xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] }]}
                                series={[
                                    {
                                        data: organizationCounts,
                                        showMark: ({ index }) => index % 2 === 0,
                                    },
                                ]}
                                width="100%"
                                height={300}
                            />
                        </Stack>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card style={{ padding: '30px 20px' }}>
                        <Stack sx={{ width: '100%' }}>
                            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                                Total users registered per month <span style={{ color: '#f07e1d', fontSize: 16 }}>({year})</span>
                            </Typography>
                            <LineChart
                                xAxis={[{ data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] }]}
                                // xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] }]}
                                series={[
                                    {
                                        data: userCounts,
                                        showMark: ({ index }) => index % 2 === 0,
                                    },
                                ]}
                                width="100%"
                                height={300}
                            />
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashboardManagement;
